//@import "../node_modules/bootstrap/scss/functions";
//@import "../node_modules/bootstrap/scss/variables";
//Fonts
@import "assets/scss/fonts/fonts";

//Core files
@import "/node_modules/bootstrap/scss/functions";
@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/mixins";
@import "assets/scss/config/saas/variables.scss";
@import "assets/scss/config/saas/app.scss";
// :root {
//   scroll-behavior: revert !important;
// }
.pagination {
  .paginationWrapper {
    padding: 2rem 0;
    display: flex;
    justify-content: center;
  }

  .separator {
    width: 1rem;
    margin: 0 0.25rem;
    margin-top: 0.3rem;
  }

  .pageItem {
    background: transparent;
    border: none;
    height: 2.7rem;
    width: 2.7rem;
    margin: 0 0.25rem;
    border-radius: 18%;
    font-weight: 600;
    color: $gray-600;
    background-color: $gray-200;
    font-size: 12px;
    padding: 0px;

    &:hover {
      background-color: $gray-300;
    }

    &:focus {
      outline: 0;
    }
  }

  .active {
    background-color: $primary !important;
    color: $white;
  }

  .sides {
    box-shadow:
      transparent 0px 0px 0px 1px,
      transparent 0px 0px 0px 4px,
      rgba(0, 0, 0, 0.18) 0px 2px 4px;
    font-size: 14px;

    &:hover {
      text-decoration: none;
      box-shadow:
        transparent 0px 0px 0px 1px,
        transparent 0px 0px 0px 4px,
        rgba(0, 0, 0, 0.12) 0px 6px 16px;
      background-color: $primary;
      color: white;
    }
  }
}
.react-datepicker-wrapper {
  display: block !important;
}
.date-picker {
  min-height: 42.94px;
  width: 100%;
  border: 0.1px solid $gray-500 !important;
  border-radius: 5px;
}

.datepicker-container input {
  width: 100%;
  color: hsl(0, 0%, 20%);
  padding-left: 8px;
  box-sizing: border-box;
}
.css-13cymwt-control {
  border: 0.1px solid var(--tb-border-color) !important;
}
.switch-custom {
  height: 20px !important;
  width: 60px !important;
}
html[data-bs-theme="dark"] {
  .date-picker {
    border-color: var(--vz-input-border);
    background: var(--tb-secondary-bg);
    color: white;
  }

  // ::placeholder {
  //   color: white !important;
  /* Change the color of the placeholder text in dark mode here */
  // }
}
.mce-content-body {
  background: green;
}

html[data-bs-theme="light"] {
  .main-content {
    background: $gray-100;
    min-height: 100svh;
  }
  .react-select-field {
    color: white;
    div {
      border-color: $gray-500 !important;
      background: var(--tb-secondary-bg);
      color: $gray-700;

      [class*="singleValue"] {
        color: black;
        z-index: 0;
      }
    }
  }
}

[data-bs-theme*="dark"] .w-color-compact {
  background-color: var(--vz-body-color) !important;
  color: #fff;
}

.tox-sidebar-wrap {
  border: 1px solid $gray-500 !important;
}
.tox-editor-header {
  border: 1px solid $gray-500 !important;
  border-bottom: 0 !important;
}

.w-color-compact {
  margin-left: 0 !important;
}

html[data-bs-theme="dark"] {
  .react-select-field {
    color: white;
    div {
      border-color: var(--vz-input-border) !important;
      background: var(--tb-secondary-bg);
      color: white;

      [class*="singleValue"] {
        color: white;
        z-index: 0;
      }
    }
  }

  .MuiFormControl-root {
    border-color: var(--vz-input-border);
    background: var(--vz-input-bg);

    .MuiSelect-select {
      color: var(--vz-body-color);

      span {
        color: var(--vz-body-color);
      }
    }

    svg {
      color: white;
    }
  }

  .MuiPaper-root {
    background: var(--vz-input-bg);

    li {
      color: var(--vz-body-color);
    }
  }
}
.tox-notifications-container {
  visibility: hidden !important;
}

.react-datepicker-popper {
  z-index: 3;
}
.tox-tinymce {
  border: 0 !important;
}
.navbar-nav {
  max-height: 90vh !important;
  overflow: auto !important;
}
.cursor-pointer {
  cursor: pointer;
}
.profile-user .profile-photo-edit {
  position: absolute;
  right: 0.2rem;
  left: auto;
  top: 3.2rem;
  cursor: pointer;
}

.form-control {
  border-color: $gray-500 !important;
}
.cursor-move {
  cursor: move !important;
}

.table .form-check .form-check-input {
  border: 1px solid $gray-600;
}
.center {
  text-align: center !important;
}
.padding-active {
  padding: 0.5rem 1rem !important;
}
.top-12 {
  top: 12px;
}
.required-field-news {
  &:after {
    color: $danger;
    content: " *";
  }
}
.tox-editor-header {
  z-index: 0 !important;
}
.pointer {
  cursor: pointer;
}
.editor-height {
  height: 450px;
}
.test-user {
  .css-1h06qz8-control {
    border: 1px solid #adb5bd !important;
  }
  .form-control {
    font-size: 14px;
  }
}
.show-img-form {
  height: 200px;
  width: 200px;
  object-fit: cover;
}

.title-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 450px;
}

.title-sm-ellipsis {
  @extend .title-ellipsis;
  max-width: 290px;
}

.delete-icon-position {
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 1.5rem;
  color: #fff;
  width: 2rem;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ck p {
  padding: 10px 0 !important;
}
.ck b,
strong {
  font-weight: bold;
}

#webpack-dev-server-client-overlay, .jsplus_ui_sidebar_widgets {
  z-index: -1 !important;
}